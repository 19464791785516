import React from 'react';
import { Box } from 'theme-ui';
import ArticleTile from '../../molecules/ArticleTile';
import Carousel from '../../molecules/Carousel';
import { ArticlesCarouselProps } from './types';

const ArticlesCarousel: React.FC<ArticlesCarouselProps> = ({ items }) => (
  <Box variant="articlesCarousel.wrapper">
    <Carousel
      component={ArticleTile}
      slidesToShow={4}
      slidesToScroll={4}
      responsive={[
        {
          breakpoint: 1025,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ]}
      {...{ items }}
    />
  </Box>
);

export default ArticlesCarousel;
