import React from 'react';
import Slider from 'react-slick';
import { Box } from 'theme-ui';
import { BaseCarouselItem, CarouselProps } from './types';
// Added to vendor, because there is a problem with import in other way
import './vendor/slick.scss';
import './vendor/slick-theme.scss';

function Carousel<T extends BaseCarouselItem>({
  items,
  component: Component,
  autoplay,
  ...props
}: CarouselProps<T>): JSX.Element {
  return (
    <Box
      variant="carousel.wrapper"
      className={autoplay ? 'slider-autoplay' : ''}
    >
      <Slider {...{ autoplay, ...props }} dots arrows={false}>
        {items.map(({ key, ...item }) => (
          <Box {...{ key }} className="slide" variant="carousel.slide">
            <Component {...item} />
          </Box>
        ))}
      </Slider>
    </Box>
  );
}

export default Carousel;
