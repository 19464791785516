/** @jsx jsx */
import { Box, Text, jsx } from 'theme-ui';
import { Link } from 'gatsby';
import RevealAnimationWrapper from '../../atoms/RevealAnimationWrapper';
import ArticlesCarousel from '../ArticlesCarousel';
import { ArticlesSectionProps } from './types';

const ArticlesSection: React.FC<ArticlesSectionProps> = ({
  title,
  items,
  seeAllHref,
  seeAllText = 'See all insights',
}) => (
  <RevealAnimationWrapper>
    <Box>
      {!!title && (
        <Text variant="articlesSection.title" mb="2rem" as="h2">
          {title}
        </Text>
      )}
      <ArticlesCarousel {...{ items }} />
      {!!seeAllHref && (
        <Box mt={['2rem', '1rem', '8.875rem']}>
          <Link
            to={seeAllHref}
            sx={{
              variant: 'pages.page.link',
            }}
          >
            {seeAllText}
          </Link>
        </Box>
      )}
    </Box>
  </RevealAnimationWrapper>
);

export default ArticlesSection;
