import React from 'react';
import InnerHTML from 'dangerously-set-html-content';

type IProps = {
  html: string;
};
const HTMLWrapper: React.FC<IProps> = ({ html }) => (
  <InnerHTML html={html.replace(/@/gim, '@&#8203;')} />
);

export default HTMLWrapper;
